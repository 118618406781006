import React from 'react';
import { useStyletron } from "baseui";
import { TableBuilder, TableBuilderColumn } from "baseui/table-semantic";

import moment from "moment";

import { toPercentage } from "../utils";

function Lending({ lending }) {
  const [css] = useStyletron();
  const [sortColumn, setSortColumn] = React.useState(null);
  const [sortAsc, setSortAsc] = React.useState(true);
  const [data, setData] = React.useState(lending);

  const sortedData = React.useMemo(() => {
    return data.slice().sort((a, b) => {
      const left = sortAsc ? a : b;
      const right = sortAsc ? b : a;
      const leftValue = String(left[sortColumn]);
      const rightValue = String(right[sortColumn]);
      return leftValue.localeCompare(rightValue, 'en', {
        numeric: true,
        sensitivity: 'base',
      });
    });
  }, [sortColumn, sortAsc, data]);

  function handleSort(id) {
    if (id === sortColumn) {
      setSortAsc(asc => !asc);
    } else {
      setSortColumn(id);
      setSortAsc(true);
    }
  }

  // 计算总借出金额
  const totalAmount = sortedData.reduce((acc, item) => acc + item.amount, 0);

  function formatDuration(exp) {
    const duration = moment.duration(moment().diff(moment(exp)));
    const days = duration.days();
    const hours = duration.hours();
    if (days > 0) {
      return `${days}天 ${hours}小时`;
    }
    return `${hours}小时`;
  }

  return (
    <div className={css({ margin: "0 -20px" })}>
      <TableBuilder
        data={sortedData}
        sortColumn={sortColumn}
        sortOrder={sortAsc ? 'ASC' : 'DESC'}
        onSort={handleSort}
      >
        <TableBuilderColumn id="index" header="#" sortable={false}>
          {(_, index) => index + 1}
        </TableBuilderColumn>
        <TableBuilderColumn id="amount" header="金額" sortable>
          {row => `$${row.amount.toFixed(2)}`}
        </TableBuilderColumn>
        <TableBuilderColumn
          id="period"
          header="天數"
          sortable
        >
          {row => row.period}
        </TableBuilderColumn>
        <TableBuilderColumn
          id="rate"
          header="年化率"
          sortable
        >
          {row => toPercentage(row.rate)}
        </TableBuilderColumn>
        <TableBuilderColumn
          id="exp"
          header="期限"
          sortable
        >
          {row => formatDuration(row.exp)}
        </TableBuilderColumn>
        <TableBuilderColumn
          id="time"
          header="时间"
          sortable
        >
          {row => moment(row.exp).format('YYYY-MM-DD HH:mm:ss')}
        </TableBuilderColumn>
      </TableBuilder>
      <div className={css({ marginTop: "20px", textAlign: "right" })}>
        <strong>总借出金额: ${totalAmount.toFixed(2)}</strong>
      </div>
    </div>
  );
}

export default Lending;