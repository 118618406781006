import React from 'react';
import { useStyletron } from "baseui";
import { TableBuilder, TableBuilderColumn } from "baseui/table-semantic";
import moment from "moment";

function Borrowing({ borrowing }) {
  const [css] = useStyletron();
  const [sortColumn, setSortColumn] = React.useState(null);
  const [sortAsc, setSortAsc] = React.useState(true);

  const sortedData = React.useMemo(() => {
    if (!borrowing) return [];
    return [...borrowing].sort((a, b) => {
      if (sortColumn === null) return 0;
      const left = sortAsc ? a : b;
      const right = sortAsc ? b : a;
      const leftValue = String(left[sortColumn]);
      const rightValue = String(right[sortColumn]);
      return leftValue.localeCompare(rightValue, 'en', {
        numeric: true,
        sensitivity: 'base',
      });
    });
  }, [sortColumn, sortAsc, borrowing]);

  function handleSort(id) {
    if (id === sortColumn) {
      setSortAsc(asc => !asc);
    } else {
      setSortColumn(id);
      setSortAsc(true);
    }
  }

  return (
    <div className={css({ margin: "0 -20px" })}>
      <TableBuilder
        data={sortedData}
        sortColumn={sortColumn}
        sortOrder={sortAsc ? 'ASC' : 'DESC'}
        onSort={handleSort}
      >
        <TableBuilderColumn id="amount" header="金額" sortable>
          {row => `$${parseFloat(row.amount).toFixed(2)}`}
        </TableBuilderColumn>
        <TableBuilderColumn id="period" header="天數" sortable>
          {row => row.period}
        </TableBuilderColumn>
        <TableBuilderColumn id="rate" header="年化率" sortable>
          {row => `${row.rate}%`}
        </TableBuilderColumn>
        <TableBuilderColumn id="time" header="借出時間" sortable>
          {row => moment(row.time).format('YYYY-MM-DD HH:mm:ss')}
        </TableBuilderColumn>
      </TableBuilder>
    </div>
  );
}

export default Borrowing;